@tailwind components;

@layer components {
  .main-header {
    @apply border-0 rounded-3 py-6 !bg-paper !shadow-md !transition-all !flex-row items-center flex-nowrap justify-center;
  }

  .container {
    @apply px-4 mx-auto w-full sm:max-w-[540px] md:max-w-[720xpx] lg:max-w-[960px] xl:max-w-[1140px] 2xl:max-w-[1536px];
  }

  .drawer-paper {
    transition: all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1);
    transition-property: top, bottom, width;
    transition-duration: .2s, .2s, .35s;
    transition-timing-function: linear, linear, ease;
    width: 260px;
    box-shadow: 0 10px 30px -12px rgba(0, 0, 0, 0.42), 0 4px 25px 0 rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2);
    @apply border-0 bottom-0 top-0 right-0 left-auto fixed block h-screen overflow-y-visible text-left px-0;
  }

  .mui-list-item {
    @apply float-left relative block m-0 !p-0 w-full sm:w-auto;
  }

  .nav-link {
    color: var(--text-primary);
    padding: 0.9375rem;
    font-size: 1.2rem;
    line-height: 2rem;
    @apply relative font-normal uppercase rounded-3 inline-flex hover:bg-grey-300 focus:bg-grey-50 sm:m-0 ml-16 my-8 text-left;
  }

  .card-shadow:hover {
    box-shadow: rgba(90, 114, 123, 0.11) 0 7px 30px 0;
  }

  @media (max-width: 576px) {
    .nav-link > span:first-child {
      justify-content: flex-start;
    }

    .popper-responsive {
      z-index: 1640;
      float: none;
      width: auto;
      margin-top: 0;
      background-color: transparent;
      border: 0;
      box-shadow: none;
      color: var(--text-primary);
    }
  }
}
