@import 'normalizes.css';
@import 'components.css';

@tailwind utilities;

html {
  width: 100%;
  height: 100%;
  scrollbar-gutter: stable;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(250, 251, 251);
  animation: fadeIn 1s;
  padding-right: 0 !important;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

:target {
  scroll-margin-top: 100px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

a {
  text-decoration: none;
}

button:focus {
  outline-width: 0;
}

::-webkit-scrollbar {
  width: 10px;
  background-color: rgba(0, 0, 0, 0);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

::-webkit-scrollbar:hover {
  width: 10px;
  background-color: rgba(0, 0, 0, 0.06);
}

::-webkit-scrollbar-thumb {
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:active {
  box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
  border-radius: 20px;
}

[class^="clamp-text-"],
[class*="clamp-text-"] {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -moz-box-orient: vertical;
  white-space: normal;
  text-overflow: ellipsis;
}

.clamp-text-1 {
  -webkit-line-clamp: 1;
}

.clamp-text-2 {
  -webkit-line-clamp: 2;
}

.clamp-text-3 {
  -webkit-line-clamp: 3;
}

.clamp-text-4 {
  -webkit-line-clamp: 4;
}

.rounded-3 {
  border-radius: 3px;
}
